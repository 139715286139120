<template lang="pug">
.container
  .columns.mt
    .column
      h1.title.mt.station-title
        | {{ device ? device.name : '...' }}
        device-status.ml-3(:device='device')
    .column.align-right
      button.button.is-primary(@click='openTelemetry') Telemetría
  b-message(v-if='ewsPendingCommands.length')
    span.mr-1 Hay {{ ewsPendingCommands.length }} {{ ewsPendingCommands.length === 1 ? 'comando' : 'comandos' }} en ejecución, use el ícono de comandos para más detalles
  .variables
    div(v-for='variable in variables' :key='variable.id')
      variable-analog-card.station-card(:variable='variable' v-if='variable.type === variableTypeEnum.AI')
      variable-digital-card.station-card(:variable='variable' v-else-if='variable.type === variableTypeEnum.DI')
      div(v-else) {{ variable.name }}
    b-modal(v-model='isTelemetryModalActive' :can-cancel='["x"]' has-modal-card)
      modal-card(v-if='device' :title='`Telemetría - ${device.name}`')
        telemetry(:deviceId='device.id')
</template>

<script>
import { mapState } from 'vuex'
import DeviceStatus from '@/components/devices/DeviceStatus'
import VariableAnalogCard from './VariableAnalogCard'
import VariableDigitalCard from './VariableDigitalCard'
import Telemetry from '@/components/devices/Telemetry'
import { commandTypeEnum, variableTypeEnum } from '@/constants/enums'
import variablesService from '@/services/variables.service'
import devicesService from '@/services/devices.service'
import updatesService from '@/services/updates.service'

export default {
  components: { DeviceStatus, VariableAnalogCard, VariableDigitalCard, Telemetry },
  data () {
    return {
      deviceId: null,
      device: null,
      isTelemetryModalActive: false,
      variables: [],
      variableTypeEnum,
      subscription: null
    }
  },
  created () {
    this.deviceId = this.$route.params.id
    this.getVaribales()
    this.subscription = updatesService.onVariablesUpdate(this.deviceId).subscribe(newVariable => {
      const currentVariable = this.variables.find(variable => variable.id === newVariable.id)
      if (currentVariable) {
        Object.assign(currentVariable, newVariable)
      }
    })
  },
  beforeDestroy () {
    this.subscription.unsubscribe()
  },
  computed: {
    ...mapState({
      pendingCommands: state => state.commands.pendingCommands
    }),
    ewsPendingCommands () {
      return this.pendingCommands.filter(command => [commandTypeEnum.OpenOutput, commandTypeEnum.CloseOutput].includes(command.type))
    }
  },
  methods: {
    async getVaribales () {
      const typesId = [variableTypeEnum.DI, variableTypeEnum.AI].join()
      const deviceId = this.deviceId
      const variablesRequest = variablesService.getAll({ deviceId, typesId })
      const deviceRequest = devicesService.getOne(deviceId)
      const [variablesResult, deviceResult] = await Promise.all([variablesRequest, deviceRequest])
      if (variablesResult && deviceResult) {
        this.variables = variablesResult
        this.device = deviceResult
      }
    },
    openTelemetry (device) {
      this.isTelemetryModalActive = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~bulma/sass/utilities/initial-variables";

.variables {
  margin-top: $gap;
  display: grid;
  column-gap: $gap / 2;
  row-gap: $gap / 2;
  grid-template-columns: repeat(2, 1fr);
}

.station-card {
  min-height: 240px;
}

.station-title {
  display: flex;
  align-items: center;
}
</style>
